<template>
  <v-container fluid>
    <PageToolbar
      :title="$tc('global.conta', 2) + ' ' + $tc('global.pagar')"
      icon="mdi-cash-minus"
      dark
    />
    <v-card class="pa-4">
      <v-card flat color="d-flex ">
        <SearchFornecedores
          style="max-width: 450px"
          class="mr-2"
          :fornecedor_async.sync="selectedFornecedor"
        />
        <v-text-field
          class="mx-2"
          :label="$tc('global.documento')"
          outlined
          v-model="documento"
          dense
          hide-details
        ></v-text-field>
        <v-text-field
          class="ml-2"
          :label="$t('global.descricao')"
          outlined
          dense
          v-model="descricao"
          hide-details
        ></v-text-field>
      </v-card>
      <v-card class="mt-5" outlined v-if="contas.length && selectedFornecedor">
        <v-card-title class="tocs_gray_1 px-3 py-2">
          <v-text-field
            style="max-width: 250px"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$tc('global.buscar')"
            hide-details
            outlined
            solo
            flat
            dense
          ></v-text-field>
          <v-spacer></v-spacer>
          <span class="subtitle-1 font-weight-bold">
            {{ selectedFornecedor.nome }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <v-data-table
            v-if="!loading"
            v-model="contasSelected"
            :headers="headers"
            :search="search.length >= 3 ? search : ''"
            :items="contas"
            item-key="id"
            sort-by="id"
            :show-select="fornecedor_baixa_processando ? false : true"
            :items-per-page="10"
            class="data-tables"
            :item-class="row_classes"
            @item-selected="contaSelected"
          >
            <template v-slot:item.id="{ item }">
              <div class="d-flex justify-space-between">
                <span>
                  {{ item.id }}
                </span>
                <v-tooltip v-if='item.origem_tabela == "ORDEM_SERVICO"' bottom color="darkblue">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      icon
                      @click.stop="openVendaInfo(item)"
                      v-on="on"
                    >
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Informações da venda</span>
                </v-tooltip> 
              </div>
            </template>
            <template v-slot:item.data_vencimento="{ item }">
              <div class="d-flex align-center">
                <div v-if="item.data_vencimento">
                  {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
                </div>
              </div>
            </template>
            <template v-slot:item.data_lancamento="{ item }">
              <div class="d-flex align-center">
                <div v-if="item.data_lancamento">
                  {{ item.data_lancamento | dateFormat("dd/MM/yyyy") }}
                </div>
              </div>
            </template>
            <template v-slot:item.moeda_sigla="{ item }">
              <div v-if="item.moeda_sigla">
                {{ item.moeda_sigla }}
              </div>
            </template>
            <template v-slot:item.valor="{ item }">
              <div v-if="item.moeda_sigla == 'G$' && item.valor">
                {{ item.valor | guarani }}
              </div>
              <div v-else-if="item.valor">
                {{ item.valor | currency }}
              </div>
            </template>

            <template v-slot:item.desconto="{ item }">
              <div v-if="item.moeda_sigla == 'G$' && item.desconto">
                {{ item.desconto | guarani }}
              </div>
              <div v-else-if="item.desconto">
                {{ item.desconto | currency }}
              </div>
            </template>
            <template v-slot:item.valor_pago="{ item }">
              <div v-if="item.moeda_sigla == 'G$'">
                {{ item.valor_pago | guarani }}
              </div>
              <div v-else-if="item.valor_pago">
                {{ item.valor_pago | currency }}
              </div>
            </template>

            <template v-slot:item.saldo="{ item }">
              <div v-if="item.moeda_sigla == 'G$' && item.saldo">
                {{ item.saldo | guarani }}
              </div>
              <div v-else-if="item.saldo">
                {{ item.saldo | currency }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-card
        outlined
        v-if="contasSelectedFornecedor.length"
        class="mt-5 pa-4 d-flex"
      >
        <v-spacer></v-spacer>
        <v-btn depressed small color="primary" class="mr-2" @click="resetBaixa">
          reset
          <v-icon right>mdi-lock-reset</v-icon>
        </v-btn>
        <v-btn
          depressed
          small
          class="white--text button_1"
          :disabled="disableBaixarContas"
          @click="baixarContas"
        >
          Baixar Contas
          <v-icon right>mdi-content-save-outline</v-icon>
        </v-btn>
      </v-card>
      <v-card
        class="text-center mt-8 mb-4"
        flat
        v-if="fornecedor && contas.length == 0 && !loading"
      >
        Fornecedor não possui contas para ser dado baixa!
      </v-card>
      <template v-if="contasSelectedFornecedor">
        <template v-for="(contas_fornecedor, i) in contasSelectedFornecedor">
          <BaixarContas
            :key="i"
            :contas="contas_fornecedor.contas"
            :fornecedor_id="contas_fornecedor.fornecedor_id"
            :cliente_nome="contas_fornecedor.fornecedor_nome"
            :recibo="contas_fornecedor.recibo"
            tipo_baixa="pagamento"
            @updateContasBaixar="updateContasBaixar"
            @get-contas="fetchContasPagar"
            @deleteContaCliente="deleteContaFornecedor"
            @editarConta="editarConta"
          />
        </template>
      </template>
      <v-overlay :value="overlay">
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-center">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-col>
          <v-col cols="12" c style="width: 800px !important">
            <v-progress-linear
              :value="valueDeterminate"
              :background-opacity="0.5"
              color="tocs_red"
              height="40px"
              rounded
              class="white--text"
            >
              <template v-slot="{}">
                <strong>
                  {{ contasBaixar.length + " " + $tc("global.contasRestante") }}
                </strong>
              </template>
            </v-progress-linear>
          </v-col>
        </v-row>
      </v-overlay>
    </v-card>
    <DialogVendaInfo
      v-if="dialogVendaInfo"
      :dialogVendaInfo.sync="dialogVendaInfo"
      :venda="venda"
      :venda_parcelas="venda_parcelas"
    />
  </v-container>
</template>

<script>
import { getContasPagar } from "@/api/contas-pagar/contas_pagar.js";
import { getVendaInfo, getVendaIdByOrdemServicoId } from "@/api/vendas/vendas.js";
import { postBaixaContasFornecedores } from "@/api/baixa-contas/baixa_contas.js";
import { format } from "date-fns";

export default {
  name: "BaixaDeContasPagar",
  props: {},
  components: {
    SearchFornecedores: () =>
      import("@/components/fields/SearchFornecedores.vue"),
    BaixarContas: () => import("./components/BaixarContas.vue"),
    DialogVendaInfo: () => import("@/views/dashboard/caixas-pdv/components/DialogVendaInfo.vue"),
  },
  data() {
    return {
      valueDeterminate: 0,
      overlay: false,
      loading: false,
      selectedFornecedor: null,
      fornecedor: null,
      documento: "",
      descricao: "",
      search: "",
      contas: [],
      contasSelectedFornecedor: [],
      contasSelected: [],
      contasBaixar: [],
      fornecedor_baixa_processando: false,
      venda: null,
      venda_parcelas: [],
      dialogVendaInfo: false,
    };
  },
  computed: {
    disableBaixarContas() {
      let result = true;
      if (this.contasBaixar.length === this.contasSelectedFornecedor.length) {
        result = false;
      }

      return result;
    },
    headers() {
      return [
        {
          text: "Id",
          value: "id",
          width: "40px",
        },
        {
          text: this.$tc("global.documento"),
          value: "documento",
          width: "150px",
        },
        {
          text: this.$tc("global.data-vencimento"),
          align: "start",
          value: "data_vencimento",
          width: "150px",
        },
        {
          text: this.$tc("global.historico"),
          width: "150px",
          value: "historico",
        },
        {
          text: this.$tc("global.moeda"),
          value: "moeda_sigla",
          width: "150px",
          align: "start",
        },
        {
          text: this.$tc("global.valor"),
          width: "150px",
          value: "valor",
        },
        {
          text: this.$tc("global.data-pagamento"),
          align: "start",
          width: "150px",
          value: "data_pagamento",
        },
        {
          text: this.$tc("global.desconto"),
          width: "150px",
          align: "right",
          value: "desconto",
        },
        {
          text: this.$tc("global.parcial"),
          width: "150px",
          align: "right",
          value: "valor_pago",
        },
        {
          text: this.$tc("global.saldo"),
          width: "150px",
          align: "right",
          value: "saldo",
        },
      ];
    },
  },
  watch: {
    selectedFornecedor() {
      if (this.selectedFornecedor) {
        this.fornecedor = this.selectedFornecedor;
        let fornecedor_baixa_existe = this.contasBaixar.some(
          (conta_fornecedor) => {
            return conta_fornecedor.fornecedor_id === this.fornecedor.id;
          }
        );
        if (fornecedor_baixa_existe) {
          this.fornecedor_baixa_processando = true;
        } else {
          this.fornecedor_baixa_processando = false;
        }
      }
    },

    async fornecedor() {
      if (this.fornecedor) {
        await this.fetchContasPagar();
      }
    },

    contasSelected() {
      if (this.contasSelected.length) {
        this.putContasSelectedFornecedor();
      }
    },
  },
  methods: {
    row_classes(item) {
      let dataNow = "";
      dataNow = format(new Date(), "yyyy-MM-dd");
      if (item.data_vencimento < dataNow) {
        return "tocs_red--text";
      }
      if (!item.autorizado_pago){
        return "tocs_orange--text";
      }
    },
    resetBaixa() {
      this.contasBaixar = [];
      this.contasSelectedFornecedor = [];
      this.contasSelected = [];
    },
    async openVendaInfo(item) {
      let ordem_servico_id = item.origem_id;
      let venda_id = await getVendaIdByOrdemServicoId(ordem_servico_id);
      this.venda = await getVendaInfo(venda_id);
      if (this.venda.parcelas) {
        this.venda_parcelas = this.venda.parcelas;
      }
      this.dialogVendaInfo = true;
    },
    // PEGA TODAS AS CONTAS A PAGAR PARA O FORNECEDOR
    fetchContasPagar() {
      this.loading = true;
      return getContasPagar(
        `?fornecedor_id=${this.fornecedor.id}&nao_pagas=${true}`
      )
        .then((response) => {
          this.contas = response;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // SELECIONA CONTAS PARA BAIXA E VALIDA SE A MOEDA É IGUAL
    contaSelected({ item, value }) {
      if (value) {
        let result = false;

        this.contasSelected.some((conta, index) => {
          if (conta.moeda_sigla != item.moeda_sigla) {
            result = true;
            return true;
          }
        });

        if (result) {
          this.$nextTick(() => {
            const index = this.contasSelected.findIndex(
              (conta) => conta.id === item.id
            );
            if (index !== -1) this.contasSelected.splice(index, 1);

            if (this.contasSelected.length) {
              let old_moeda = this.contasSelected[0].moeda_sigla;
              this.$toast.error(`Moeda deve ser igual à ${old_moeda}$`);
            } else {
              this.$toast.error(`Moedas devem ser iguais.`);
            }
          });
        }
      }
    },
    // PEGA TODAS AS CONTAS SELECIONADAS E COLOCA NO ARRAY DE FORNECEDORES SELECIONADOS
    putContasSelectedFornecedor() {
      let fornecedor_contas = {};
      let fornecedor_existe = null;

      fornecedor_contas.fornecedor_id = this.fornecedor.id;
      fornecedor_contas.fornecedor_nome = this.fornecedor.nome;
      fornecedor_contas.contas = this.contasSelected;

      fornecedor_existe = this.contasSelectedFornecedor.some(
        (conta_fornecedor) => {
          return conta_fornecedor.fornecedor_id === this.fornecedor.id;
        }
      );
      if (fornecedor_existe) {
        let index = null;
        index = this.contasSelectedFornecedor.findIndex((conta_fornecedor) => {
          return conta_fornecedor.fornecedor_id === this.fornecedor.id;
        });
        this.contasSelectedFornecedor[index].contas = this.contasSelected;
      } else {
        this.contasSelectedFornecedor.push(fornecedor_contas);
      }
    },
    updateContasBaixar(body) {
      let conta_existe = this.contasBaixar.some((conta) => {
        return conta.fornecedor_id === body.fornecedor_id;
      });
      if (conta_existe) {
        let index = this.contasBaixar.findIndex((conta) => {
          return conta.fornecedor_id === body;
        });
        this.contasBaixar.splice(index, 1);
        this.contasBaixar.push(body);
      } else {
        this.contasBaixar.push(body);
      }
    },
    deleteContaFornecedor() {
      let index = this.contasSelectedFornecedor.findIndex((conta) => {
        return !conta.contas.length;
      });
      this.contasSelectedFornecedor.splice(index, 1);
    },
    editarConta(item) {
      let index = this.contasBaixar.findIndex((conta) => {
        return conta.fornecedor_id === item;
      });
      this.contasBaixar.splice(index, 1);
    },

    // SALVA CONTAS PARA BAIXA
    async baixarContas() {
      try {
        if (this.contasBaixar.length === this.contasSelectedFornecedor.length) {
          let contas = [];
          this.contasBaixar.map((conta) => {
            conta.documento = this.documento;
            conta.descricao_pagamento = this.descricao;
            contas.push(conta);
          });
          this.overlay = true;
          let total = contas.length - 1;
          let i = 0;
          contas.forEach((conta) => {
            postBaixaContasFornecedores(conta)
              .then((response) => {
                if (response.status === 200) {
                  this.$toast.success(
                    `${i + 1} de ${total + 1} efetuada com sucesso!`
                  );
                  i++;
                  this.valueDeterminate = (i / total) * 100;
                  this.contasSelectedFornecedor.map((fornecedor, index) => {
                    if (
                      fornecedor.fornecedor_id == response.data.fornecedor_id
                    ) {
                      this.contasSelectedFornecedor[index].recibo =
                        response.data.recibo;
                    }
                  });
                }
              })
              .finally(() => {});
          });

          this.overlay = false;
          this.contasSelected = [];
          this.contasBaixar = [];
          this.fetchContasPagar();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
